import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LayoutWithFirebase from '../components/LayoutWithFirebase';
import Button from '../components/Button';
import { withFirebase } from '../components/Firebase';

import useForm, { DEFAULT_FIELD } from '../hooks/useForm';

const registerUser = async ({ inputs, props }) => {
  const { user } = await props.firebase.auth.signInAnonymously();
  const ref = props.firebase.db.ref(`/registrations/${user.uid}`);
  try {
    await ref.set({ email: inputs.fields.email.value, name: inputs.fields.name.value, phone: inputs.fields.phone.value, timestamp: Date.now() });
    navigate('/');

  } catch (error) {
    console.log('we should handle this error');
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(8),
    padding: theme.spacing(4),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formFooter: {
    margin: theme.spacing(4),
  }
}));

const formFields = {
  name: DEFAULT_FIELD,
  email: DEFAULT_FIELD,
  phone: DEFAULT_FIELD,
}

const RegisterNowBase = (props) => {
  const classes = useStyles();
  const {inputs, handleInputChange, handleSubmit } = useForm(props, registerUser, formFields);
  return (
      <Paper elevation={1}>
      <form className={classes.container} noValidate autoComplete="off">
        <Typography className={classes.paragraphText} component='h3' variant='h3'>Register Now!</Typography>
        <TextField
          id="standard-name"
          label="Name"
          placeholder="Enter your name"
          className={classes.textField}
          value={inputs.fields.name.value}
          onChange={handleInputChange('name')}
          margin="normal"
        />
        <TextField
          id="standard-name"
          label="Email"
          placeholder="Enter your email"
          className={classes.textField}
          value={inputs.fields.email.value}
          onChange={handleInputChange('email')}
          margin="normal"
        />
        <TextField
          id="standard-name"
          label="Phone"
          placeholder="Enter your mobile number"
          className={classes.textField}
          value={inputs.fields.phone.value}
          onChange={handleInputChange('phone')}
          margin="normal"
        />
        <div className={classes.formFooter} >
          <Button variant="contained" color="primary" onClick={(event) => handleSubmit(event, inputs)}>Submit</Button>
        </div>
      </form>
      </Paper>
  )
}

const RegisterNow = withFirebase(RegisterNowBase);

export default () => (
    <LayoutWithFirebase>
      <RegisterNow />
    </LayoutWithFirebase>
  );
